<template>
  <div class="app-container">
    <div class="right_btn">
      <el-button size="small" type="primary" icon="Plus" @click="add"
        >新增</el-button
      >
    </div>
    <div class="table-box">
      <div class="table-header">
        <span>序号</span>
        <span>单据类型</span>
        <span>最后修改人</span>
        <span>启用状态</span>
        <span>最后修改日期</span>
        <span>操作</span>
      </div>
      <div class="table-body">
        <div v-for="(item, index) in typeList" :class="['table-row', { active: index === choosedRowsIndex }]" @click="() => rowClick(item, index)">
          <div>{{ index + 1 }}</div>
          <div>
            <span v-if="item.type == 2">合同</span>
            <span v-if="item.type == 3">收款</span>
            <span v-if="item.type == 4">退款</span>
          </div>
          <div>{{ item.updateName }}</div>
          <div>
            <el-tag :type="item.status == 1 ? 'success' : 'info'">{{
              item.status == 1 ? "启用" : "关闭"
            }}</el-tag>
          </div>
          <div>
            <span>{{ $parseTime(item.updatedAt, "{y}-{m}-{d}") }}</span>
          </div>
          <div>
            <el-button size="small" link @click.stop="edit(item)"
              >修改</el-button
            >
            <el-button
              size="small"
              type="danger"
              link
              @click.stop="del(item)"
              >删除</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <el-table
      :data="list"
      style="width: 100%; margin-top: 10px"
      border
      v-loading="loading"
    >
      <el-table-column type="index" label="序号" width="55" align="center" />
      <el-table-column prop="req" label="审核顺序" width="150" align="center">
      </el-table-column>
      <el-table-column prop="userName" label="审核人名称" width="150">
      </el-table-column>
    </el-table>
  </div>
  <orderForm ref="orderFormRef" @success="getList" />
</template>
<script setup>
import { ref, onMounted } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { approvalTypeList, approvalList, delApproval } from "@/api/newCrm";
import orderForm from "./components/orderForm.vue";
const list = ref([]);
const loading = ref(false);
const orderFormRef = ref();
const type = ref();
const typeList = ref([]);
const choosedRowsIndex = ref()

onMounted(() => {
  getList();
});
const getList = async () => {
  await approvalTypeList({}).then((res) => {
    typeList.value = res.data.data.list ? res.data.data.list : [];
    if (!type.value && typeList.value?.length) {
      type.value = typeList.value[0].type;
      choosedRowsIndex.value = 0;
    }
  });
  if (type.value) {
    approvalList({ type: type.value }).then((res) => {
      if (res.data.msg == "success") {
        list.value = res.data.data.list;
      }
    });
  }
};
const add = () => {
  orderFormRef.value.init();
};
const edit = (row) => {
  orderFormRef.value.init(row);
};
const del = (row) => {
  ElMessageBox.confirm("确定要删除此单据类型的审批流程吗？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    delApproval({ type: row.type }).then((res) => {
      if (res.data.msg == "success") {
        ElMessage.success("操作成功");
        type.value = "";
        list.value = [];
        getList();
      }
    });
  });
};
const rowClick = (row, i) => {
  choosedRowsIndex.value = i;
  approvalList({ type: row.type }).then((res) => {
    if (res.data.msg == "success") {
      list.value = res.data.data.list;
    }
  });
};
</script>
<script>
export default {
  name: "checkSet",
};
</script>

<style scoped lang="scss">
.right_btn {
  text-align: right;
  margin-bottom: 10px;
}

.table-box {
  font-size: 13px;
}

.table-header {
  display: flex;
  height: 34px;
  span {
    display: block;
    width: 150px;
    height: 34px;
    padding: 0 8px;
    color: #fff;
    line-height: 34px;
    background-color: var(--themeColor, #17a2b8);
    border-right: 1px solid #b9b9b9;
    border-bottom: 1px solid #b9b9b9;
    box-sizing: border-box;
  }
  span:first-of-type {
    width: 55px;
    border-left: 1px solid #b9b9b9;
  }
}

.table-row {
  display: flex;
  & > div {
    display: flex;
    align-items: center;
    width: 150px;
    height: 34px;
    padding: 0 8px;
    box-sizing: border-box;
    border-right: 1px solid #b9b9b9;
    border-bottom: 1px solid #b9b9b9;
  }
  & > div:first-of-type {
    width: 55px;
    border-left: 1px solid #b9b9b9;
  }

  &.active {
    & > div {
      background-color:  #ecf7f9;
    }
    
  }
}
</style>
