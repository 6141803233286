<template>
  <el-drawer v-model="drawer" size="630px">
    <template #header>
      <div class="header_top">
        <div>新增</div>
        <div>
          <el-button type="primary" @click="save" size="small">保存</el-button>
        </div>
      </div>
    </template>
    <el-form
      :model="form"
      label-width="118px"
      :rules="rules"
      size="small"
      ref="ruleFormRef"
      :inline="true"
    >
      <el-form-item
        label="单据类型"
        prop="type"
        :rules="[
          {
            required: true,
            message: '请选择单据类型',
            trigger: 'change',
          },
        ]"
      >
        <select-common
          placeholder="请选择单据类型"
          v-model:modelValue="form.type"
          :options="options"
          :disabled="formType == 'edit'"
        />
      </el-form-item>
      <el-form-item label="是否开启" prop="status">
        <el-checkbox v-model="form.status" :true-label="1" :false-label="0" />
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          placeholder="请输入备注"
          class="w370"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-card style="width: 100%">
      <template #header>
        <div class="header_top">
          审核顺序
          <el-button
            size="small"
            @click="addServer(form.approval)"
            type="primary"
            v-if="form.approval.length == 0"
            >新增</el-button
          >
        </div>
      </template>
      <el-table :data="form.approval" style="width: 100%" border>
        <el-table-column label="审核顺序" width="120">
          <template #default="scope">
            <el-input
              v-model="scope.row.req"
              size="small"
              type="number"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="审核人" min-width="300">
          <template #default="scope">
            <select-common
              placeholder="请选择审核人"
              v-model:modelValue="scope.row.userId"
              :options="userOptions"
              valueKey="id"
              labelKey="CnName"
            />
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template #default="scope">
            <el-button size="small" link @click="addServer(form.approval)"
              >新增</el-button
            >
            <el-button
              size="small"
              link
              @click="delServer(form.approval, scope.$index)"
              type="danger"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </el-drawer>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { ElMessage } from "element-plus";
// import { findPeople } from "@/api/base";
import { saveApproval, approvalList, updateApproval, approvalUserList } from "@/api/newCrm";
const emit = defineEmits();
const options = ref([
  { value: 2, label: "合同" },
  { value: 3, label: "收款单" },
  { value: 4, label: "退款单" },
]);
const drawer = ref(false);
const form = ref({
  type: null,
  status: 0,
  approval: [],
});
const userOptions = ref([]);
const formType = ref("");
onMounted(() => {
  approvalUserList({ code: "bookkeeping" }).then((res) => {
    userOptions.value = res.data.data.list;
  });
});
const init = (row) => {
  if (row) {
    approvalList({ type: row.type }).then((res) => {
      if (res.data.msg == "success") {
        form.value.type = res.data.data.list[0].type;
        form.value.status = res.data.data.list[0].status;
        form.value.remark = res.data.data.list[0].remark;
        form.value.approval = res.data.data.list;
      }
    });
    formType.value = "edit";
  } else {
    form.value = {
      type: null,
      status: 0,
      approval: [],
    };
    formType.value = "add";
  }
  drawer.value = true;
};
const save = () => {
  if (!form.value.type) {
    ElMessage.warning("请选择单据类型");
    return;
  }
  if (form.value.approval.length < 1) {
    ElMessage.warning("至少添加一条审批流程");
    return;
  }
  let fail = form.value.approval.find((item) => !item.userId || !item.req);
  if (fail) {
    ElMessage.warning("请检查审批流程是否填写正确");
    return;
  }
  form.value.approval.map((v) => {
    v.req = v.req * 1;
  });
  if (formType.value == "add") {
    saveApproval(form.value).then((res) => {
      if (res.data.msg == "success") {
        ElMessage.success("保存成功");
        drawer.value = false;
        emit("success");
      }
    });
  } else {
    updateApproval(form.value).then((res) => {
      if (res.data.msg == "success") {
        ElMessage.success("保存成功");
        drawer.value = false;
        emit("success");
      }
    });
  }
};
const addServer = (list) => {
  list.push({
    userId: null,
    req: list.length == 0 ? 1 : list[list.length - 1].req * 1 + 1,
  });
};
const delServer = (list, index) => {
  list.splice(index, 1);
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.header_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.w370 {
  width: 370px;
  margin-left: 5px;
}
</style>
